const config = {
	apiKey: process.env.REACT_APP_API_KEY || "",
	appName: "BusStock",
	domain: "busstock.app",
	publicListUrl: "https://shop.busstock.app/",
	restrictedSubdomains: ["dashboard", "admin", "shop", "www", "email"],
	DBConfig: {
		name: "BusStock_Local",
		version: 1,
		objectStoresMeta: [
			{
				store: "items_added",
				storeConfig: { keyPath: "id", autoIncrement: true },
				storeSchema: [
					{ name: "listId", keypath: "listId", options: { unique: false } },
					{ name: "title", keypath: "title", options: { unique: false } },
					{ name: "category", keypath: "category", options: { unique: false } },
				],
			},
		],
	},
}

export default config

import TagManager from "react-gtm-module"
import ReactGA from "react-ga"
import ReactPixel from "react-facebook-pixel"
import Bugsnag from "@bugsnag/js"
import { MeInfoQuery } from "../API"
import mixpanel from "mixpanel-browser"
import { Subscription } from "chargebee-typescript/lib/resources/subscription"

// create an instance of the mixpanel client
if (process.env.REACT_APP_MIXPANEL_ID) {
	mixpanel.init(process.env.REACT_APP_MIXPANEL_ID, {
		cookie_domain: "busstock.app",
		secure_cookie: true,
	})
}

const options = {
	autoConfig: true,
	debug: false,
}
ReactPixel.init("350280329686899", undefined, options)
ReactGA.initialize("UA-173468423-1")

const tagManagerArgs = {
	gtmId: "G-PGVJFWGMSJ",
}

TagManager.initialize(tagManagerArgs)

export const trackingEvents = {
	SUBSCRIPTION_VIEWED: "Subscription Viewed",
	SUBSCRIPTION_CHECKOUT_STARTED: "Subscription Checkout Started",
	SUBSCRIPTION_CHECKOUT_COMPLETED: "Subscription Checkout Completed",
	SUBSCRIPTION_EDIT_STARTED: "Subscription Edit Started",
	SUBSCRIPTION_EDIT_COMPLETED: "Subscription Edit Completed",
	DASHBOARD_VIEWED: "Dashboard Viewed",
	HISTORY_VIEWED: "History Viewed",
	PROFILE_VIEWED: "Profile Viewed",
	SETTINGS_VIEWED: "Settings Viewed",
	CREATE_ACCOUNT_VIEWED: "Create Account Viewed",
	FORGOT_PASSWORD_VIEWED: "Forgot Password Viewed",
	LOGIN_VIEWED: "Login Viewed",
	PRIVACY_POLICY_VIEWED: "Privacy Policy Viewed",
	TERMS_VIEWED: "Terms Viewed",
	HOMEPAGE_VIEWED: "Homepage Viewed",
	VERIFY_ACCOUNT_VIEWED: "Verify Account Viewed",
	LIST_VIEWED: "List Viewed",
	LIST_CREATED: "List Created",
	LIST_UPDATED: "List Updated",
	LIST_DELETED: "List Deleted",
	LIST_ENABLED: "List Enabled",
	LIST_DISABLED: "List Disabled",
	LIST_ITEM_ADDED: "List Item Added",
	LIST_ITEM_DELETED: "List Item Deleted",
	LIST_ITEMS_PROCESSED: "List Items Processed",
	LIST_QR_CODE_GENERATED: "List QR Code Generated",
	COLLECTION_MODE_CHANGED: "Collection Mode Changed",
	USER_LOGGED_IN: "User Logged In",
	USER_LOGGED_OUT: "User Logged Out",
	USER_SIGNED_UP: "User Signed Up",
	USER_VERIFIED: "User Verified",
	USER_FORGOT_PASSWORD: "User Forgot Password",
	USER_CHANGE_PASSWORD: "User Change Password",
	USER_PASSWORD_RESET: "User Password Reset",
	ERROR_MESSAGE_DISPLAYED: "Error Message Displayed",
	SHOPPING_LIST_VIEWED: "Shopping List Viewed",
	SHOPPING_LIST_ITEM_COMPLETED: "Shopping List Item Completed",
	SHOPPING_LIST_ITEM_UNCOMPLETED: "Shopping List Item Uncompleted",
	SHOPPING_LIST_ITEM_DELETED: "Shopping List Item Deleted",
	SHOPPING_LIST_REQUEUED: "Shopping List Requeued",
	WIZARD_VIEWED: "Wizard Viewed",
	WIZARD_STEP_COMPLETED: "Wizard Step Completed",
} as const

const analytics = {
	track: (category: string, action: string, data?: any) => {
		if (process.env.REACT_APP_MIXPANEL_ID) {
			mixpanel.track(action, data)
		}
		ReactGA.event({
			category,
			action,
			label: data ? JSON.stringify(data) : undefined,
		})
		if (action === trackingEvents.USER_VERIFIED) {
			ReactPixel.track("CompleteRegistration")
		} else if (action.includes("Viewed")) {
			ReactPixel.track("ViewContent")
		} else {
			ReactPixel.trackCustom(action, data)
		}
	},
	trackSubscription: (subscription?: Subscription) => {
		if (process.env.REACT_APP_MIXPANEL_ID && subscription) {
			mixpanel.people.set("subscription", subscription.status)
			mixpanel.register({ subscription: subscription.status })
		}
	},
	identify: (meInfo: MeInfoQuery["meInfo"]) => {
		ReactGA.set({ userId: meInfo?.id })
		Bugsnag.setUser(meInfo?.id, meInfo?.email, meInfo?.name)
		if (process.env.REACT_APP_MIXPANEL_ID) {
			mixpanel.identify(meInfo?.id)
			const nameParts = meInfo?.name.split(" ")
			const first_name = nameParts.shift()
			const last_name = nameParts.join(" ")
			mixpanel.people.set({
				$name: meInfo?.name,
				$email: meInfo?.email,
				$created: meInfo?.createdAt,
				$first_name: first_name,
				$last_name: last_name,
				list_count: meInfo?.lists?.filter((list) => list?.isActive).length,
				subdomain: meInfo?.subdomain,
				is_processing_manually: meInfo?.sendEmailUtcTime === 25,
			})
		}
	},
}

export default analytics
